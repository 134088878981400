import React from 'react';
import PropTypes from 'prop-types';

const AppStoreIcon = ({ fillColor = 'currentColor', className = '' }) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon app-store-icon ${className}`}
    >
      <path
        d="M14.6053 11.8261C14.5829 9.14548 16.7999 7.84262 16.8973 7.78272C15.639 5.95571 13.6916 5.70113 13.01 5.67867C11.3771 5.50645 9.78922 6.65956 8.95782 6.65956C8.11144 6.65956 6.82315 5.70113 5.44497 5.72359C3.66982 5.75354 1.99953 6.77936 1.09323 8.38173C-0.786781 11.6389 0.613865 16.431 2.41898 19.0667C3.32528 20.3546 4.37389 21.7997 5.75955 21.7473C7.10777 21.6949 7.6171 20.8862 9.24244 20.8862C10.8603 20.8862 11.3322 21.7473 12.7403 21.7174C14.1934 21.6949 15.0997 20.422 15.976 19.1191C17.0172 17.644 17.4366 16.1839 17.4516 16.1091C17.4141 16.0941 14.6353 15.0308 14.6053 11.8261Z"
        fill={fillColor}
      />
      <path
        d="M11.947 3.94923C12.6736 3.04321 13.1679 1.80774 13.0331 0.549805C11.9845 0.594731 10.6662 1.27611 9.90973 2.16715C9.24312 2.95336 8.64391 4.23376 8.79371 5.43928C9.97714 5.52914 11.1905 4.84027 11.947 3.94923Z"
        fill={fillColor}
      />
    </svg>
  );
};

AppStoreIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default AppStoreIcon;

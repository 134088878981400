import React from 'react';
import PropTypes from 'prop-types';

import { StyledStoreButtonsContainer } from './style';
import Button from '../Button';
import PlayStoreDownloadIcon from '../../assets/icons/playStoreDownloadIcon';
import AppStoreDownloadIcon from '../../assets/icons/appStoreDownloadIcon';

const StoreButtonsContainer = ({ outline = false, ...restProps }) => {
  return (
    <StyledStoreButtonsContainer {...restProps}>
      <Button className="store-btns appstore-btn" outline={outline}>
        <AppStoreDownloadIcon className="app-store-icon icon" />
      </Button>
      <Button className="store-btns playstore-btn" outline={outline}>
        <PlayStoreDownloadIcon className="icon" />
      </Button>
    </StyledStoreButtonsContainer>
  );
};

StoreButtonsContainer.propTypes = {
  outline: PropTypes.bool,
};

export default StoreButtonsContainer;

import styled from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const StyledFooterWrapper = styled.footer`
  width: 100%;
  background-color: ${(props) => props.theme.palette.common.black};
  position: relative;
  margin-top: auto;
  .footer-content {
    width: 100%;
    max-width: ${(props) => props.theme.layout.maxWidth};
    padding: 5.2rem 1.5rem 4rem;
    color: ${(props) => props.theme.palette.primary.text};
    margin: auto;
    @media only screen {
      @media ${deviceQuery.tablet} {
        padding: 3.4rem 1rem 3.2rem;
      }
    }
    .footer-nav {
      .nav-item {
        &:not(:first-child) {
          margin-left: 1.2rem;

          @media (max-width: 1400px) {
            margin-left: 0.8rem;
          }
        }

        .nav-link {
          color: inherit;
          padding: 0.7rem 1.6rem;
          border-radius: 1rem;
          line-height: 2rem;
          font-size: ${(props) => props.theme.typography.body2};
          transition: color 0.2s ease-in, background-color 0.2s ease-in,
            opacity 0.2s ease-in;

          @media (max-width: 1400px) {
            padding: 0.6rem 1.4rem;
          }

          &:hover {
            background-color: ${({ theme }) => theme.palette.common.hover};
            color: ${({ theme }) => theme.palette.text.primary};
            opacity: 0.6;
          }
        }
      }
    }
    .row-1,
    .row-2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-container {
        color: inherit;
      }
    }
    .row-1 {
      margin-bottom: 4.5rem;
      @media only screen {
        @media ${deviceQuery.laptop} {
          flex-direction: column;
          .footer-nav {
            margin-top: 4.5rem;
          }
        }
        @media ${deviceQuery.tablet} {
          flex-direction: column;
          margin-bottom: 3.6rem;
          .footer-nav {
            display: none;
          }
        }
      }
    }

    .row-2 {
      opacity: 0.5;
      font-size: 1.4rem;
      line-height: 1.7rem;
      @media only screen {
        @media ${deviceQuery.tablet} {
          opacity: 1;
          flex-direction: column-reverse;
          .copyright-text {
            opacity: 0.5;
          }
        }
      }
      .footer-nav {
        @media only screen {
          @media ${deviceQuery.tablet} {
            margin-bottom: 5.2rem;
          }
        }
        .nav-link {
          font-size: inherit;
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import './style.js';
import { StyledErrorPage } from './style';

function ErrorPage({
  statusCode = 404,
  message = 'Page not found :(',
  title = 'Error',
  showOnlyMessage = false,
  ...props
}) {
  return (
    <StyledErrorPage className="card-body" {...props}>
      {!showOnlyMessage && (
        <>
          <h2 className="card-title title">{title}</h2>
          <p className="card-text statusCode">{statusCode}</p>
        </>
      )}
      <p className="card-text message">{message}</p>
    </StyledErrorPage>
  );
}

export default ErrorPage;

ErrorPage.propTypes = {
  statusCode: PropTypes.oneOf([404, 500]),
  title: PropTypes.string,
  message: PropTypes.string,
  showOnlyMessage: PropTypes.bool,
};

import styled from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';

export const StyledPartnerSection = styled.section`
  width: 100%;
  margin: auto;
  padding: 6rem 7rem;
  background: ${({ theme }) => theme.palette.common.white};

  @media ${deviceQuery.tablet} {
    padding: 3.8rem 1.6rem;
  }

  .section-title {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 3.2rem;
    text-transform: uppercase;
    margin: 2.4rem auto;
    text-align: center;

    @media ${deviceQuery.tablet} {
      margin: 1rem auto;
      font-size: 1.4rem;
    }

    @media ${deviceQuery.mobile} {
      font-size: 1.2rem;
    }
  }

  .partner-container {
    width: 85.6rem;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2.4rem auto;
    flex-wrap: nowrap;

    @media ${deviceQuery.tablet} {
      width: 40rem;
      margin: 1rem auto;
    }

    .partner {
      margin: 0 1rem;

      .wpt {
        width: 18.8rem;
      }

      .we-poker {
        width: 24.2rem;
      }

      .poker-king {
        width: 14.3rem;
      }

      @media ${deviceQuery.tablet} {
        .wpt {
          width: 9.5rem;
        }

        .we-poker {
          width: 12.4rem;
        }

        .poker-king {
          width: 6.4rem;
        }
      }
    }
  }

  .points-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    margin-top: 6.8rem;
    flex-wrap: nowrap;
    margin-right: -4rem;

    @media ${deviceQuery.tablet} {
      padding: 4rem 0;
      margin-top: 4.4rem;
      margin-right: -1.6rem;
    }

    .slick-list {
      .slick-slide {
      }
    }

    .slick-dots {
      li {
        margin: 0;
        .slider-indicator-wrapper {
          padding: 5px;
          margin: 0;

          .slider-indicator {
            width: 7px;
            height: 7px;
            background: ${({ theme }) => theme.palette.text.secondary};
            border-radius: 50%;
            display: block;
          }
        }

        &.slick-active {
          .slider-indicator {
            background: ${({ theme }) => theme.palette.primary.main};
          }
        }
      }
    }

    .point {
      width: 36rem !important;
      max-width: 100%;
      padding: 3.4rem;
      border-radius: 2.4rem;
      background-color: ${({ theme }) => theme.palette.common.background.card};
      flex-shrink: 0;
      min-height: 25.4rem;
      margin-right: 4rem;

      .point-img {
        width: 5.4rem;
        margin-bottom: 1.6rem;

        @media ${deviceQuery.tablet} {
          margin-bottom: 1.4rem;
        }
      }

      .point-title {
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.5rem;
        margin-bottom: 1.2rem;

        @media ${deviceQuery.tablet} {
          font-size: 2.2rem;
          line-height: 2.7rem;
          margin-bottom: 0.2rem;
        }
      }

      .point-desc {
        line-height: 30px;
        color: ${({ theme }) => theme.palette.text.secondary};

        @media ${deviceQuery.tablet} {
          line-height: 2.4rem;
        }
      }

      @media ${deviceQuery.tablet} {
        width: 27rem !important;
        padding: 2.4rem;
        min-height: 20.8rem;
        margin-right: 1.6rem;
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

const PlayStoreIcon = ({ className = '' }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon play-store-icon ${className}`}
    >
      <path
        d="M0.298478 0.76123C0.0749856 0.983343 0.000488281 1.35353 0.000488281 1.79776V18.16C0.000488281 18.6043 0.149483 18.9745 0.372975 19.1966L0.447472 19.2706L9.68514 10.09V9.94188L0.298478 0.76123Z"
        fill="url(#paint0_linear_948_1263)"
      />
      <path
        d="M12.6652 13.1994L9.61084 10.1639V9.94179L12.6652 6.90625L12.7397 6.98029L16.3901 9.05334C17.4331 9.64564 17.4331 10.6081 16.3901 11.2004L12.6652 13.1994V13.1994Z"
        fill="url(#paint1_linear_948_1263)"
      />
      <path
        d="M12.7394 13.1257L9.61051 10.0161L0.29834 19.2708C0.670827 19.641 1.19231 19.641 1.86278 19.3448L12.7394 13.1257"
        fill="url(#paint2_linear_948_1263)"
      />
      <path
        d="M12.7394 6.90656L1.86278 0.761446C1.19231 0.391259 0.670827 0.465296 0.29834 0.835484L9.61051 10.0161L12.7394 6.90656V6.90656Z"
        fill="url(#paint3_linear_948_1263)"
      />
      <path
        opacity="0.2"
        d="M12.6649 13.0513L1.86278 19.1223C1.26681 19.4925 0.745324 19.4185 0.372837 19.1223L0.29834 19.1964L0.372837 19.2704C0.745324 19.5666 1.26681 19.6406 1.86278 19.2704L12.6649 13.0513Z"
        fill="black"
      />
      <path
        opacity="0.12"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6653 13.0517L16.3902 10.9786C16.8372 10.6825 17.1352 10.3863 17.1352 10.0161C17.1352 10.3863 16.9117 10.7565 16.3902 11.0526L12.7398 13.1257L12.6653 13.0517ZM0.0134422 18.3875C0.0434898 18.697 0.127271 18.9531 0.29799 19.1227H0.305245C0.326797 19.1489 0.349239 19.1737 0.372487 19.1968V19.1227H0.305245C0.156078 18.9412 0.0495433 18.6869 0.0134422 18.3875ZM0.0134422 18.3875C0.0041538 18.2919 0 18.1911 0 18.0862V18.1602C0 18.2384 0.00461623 18.3143 0.0134422 18.3875Z"
        fill="black"
      />
      <path
        opacity="0.25"
        d="M1.86292 0.835249L16.3899 9.05341C16.8369 9.34956 17.1349 9.64571 17.1349 10.0159C17.1349 9.64571 16.9114 9.27552 16.3899 8.97937L1.86292 0.761211C0.819959 0.168911 0.000488281 0.613136 0.000488281 1.79774V1.87177C0.000488281 0.761211 0.819959 0.242949 1.86292 0.835249Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_948_1263"
          x1="5.54062"
          y1="-10.2907"
          x2="-9.24802"
          y2="-6.26727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.007" stopColor="#00A1FF" />
          <stop offset="0.26" stopColor="#00BEFF" />
          <stop offset="0.512" stopColor="#00D2FF" />
          <stop offset="0.76" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_948_1263"
          x1="17.7564"
          y1="2.51527"
          x2="-0.269747"
          y2="2.51527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.409" stopColor="#FFBD00" />
          <stop offset="0.775" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_948_1263"
          x1="0.0621481"
          y1="5.21407"
          x2="-11.7231"
          y2="25.1232"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_948_1263"
          x1="-6.94126"
          y1="-1.77097"
          x2="-1.72013"
          y2="7.13168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.069" stopColor="#2DA771" />
          <stop offset="0.476" stopColor="#15CF74" />
          <stop offset="0.801" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  );
};

PlayStoreIcon.propTypes = {
  className: PropTypes.string,
};

export default PlayStoreIcon;

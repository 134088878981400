import styled from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';
import { StyledTextImageSection } from '../../style';

export const StyledNftAvatarsSection = styled(StyledTextImageSection)`
  padding-top: 16.9rem;
  padding-bottom: 16.9rem;
  background: ${({ theme }) => theme.palette.common.white};

  @media ${deviceQuery.tablet} {
    padding-top: 7.2rem;
    padding-bottom: 8.2rem;
  }

  .subsection-container {
    @media ${deviceQuery.tablet} {
      flex-direction: column-reverse;
    }

    .image-subsection {
      @media ${deviceQuery.tablet} {
        padding-top: 1.9rem;
      }
    }
  }
`;

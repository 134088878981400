import { useEffect, useState } from 'react';

import useMemoCompare from './useMemoCompare';

const DEFAULT_OPTIONS = {
  root: null,
  rootMargin: '0px 0px 0px 0px',
  threshold: 0,
};

const useIntersectionObserver = ({
  targetRef,
  callback,
  options: _options = DEFAULT_OPTIONS,
}) => {
  const [observer, setObserver] = useState(null);

  const options = useMemoCompare(_options);

  useEffect(() => {
    let _observer;
    const targetNode = targetRef.current;
    if (targetNode) {
      _observer = new IntersectionObserver(callback, options);
      _observer.observe(targetNode);
      setObserver(_observer);
    }

    return () => {
      _observer && _observer.unobserve(targetNode);
    };
  }, [callback, options, targetRef]);

  return observer;
};

export default useIntersectionObserver;

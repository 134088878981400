import React, { useMemo } from 'react';
import { StyledPartnerSection } from './style';
import WePokerImg from '../../../../assets/images/icons/wePoker.svg';
import PokerKingImg from '../../../../assets/images/icons/pokerKing.svg';
import WPTImg from '../../../../assets/images/icons/wpt.svg';
import NFTImg from '../../../../assets/images/icons/nft.svg';
import CryptoImg from '../../../../assets/images/icons/crypto.svg';
import DiceImg from '../../../../assets/images/icons/dice.svg';
import Slider from 'react-slick';
import '../../../../styles/css/react-slick.css';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { sizeNumbers } from '../../../../styles/mediaSizes';

const DESC_POINTS = [
  {
    img: DiceImg,
    title: 'P2E Games',
    desc: 'Discover the most popular Play-2-Earn games',
  },
  {
    img: NFTImg,
    title: 'NFTs',
    desc: 'Access and store all your NFTs in one secure place',
  },
  {
    img: CryptoImg,
    title: 'Crypto',
    desc: 'Buy, store and swap the most popular cryptocurrencies',
  },
];

const CarouselSettings = {
  dots: true,
  arrows: false,
  customPaging: function Indicator() {
    return (
      <div className="slider-indicator-wrapper">
        <span className="slider-indicator"></span>
      </div>
    );
  },
  className: 'points-container',
  variableWidth: true,
  infinite: true,
  responsive: [
    {
      breakpoint: sizeNumbers.mobileXS,
      settings: {
        slidesToShow: 1,
        variableWidth: false,
      },
    },
  ],
};

const PartnerSection = () => {
  const isBreakPoint = useMediaQuery('(max-width:1216px)');

  const CardsList = useMemo(() => {
    return DESC_POINTS?.map(({ img, title, desc }) => {
      return (
        <div key={title} className="point">
          <img src={img} alt={title} className="point-img" />
          <p className="point-title">{title}</p>
          <p className="point-desc">{desc}</p>
        </div>
      );
    });
  }, []);

  return (
    <StyledPartnerSection>
      <h6 className="section-title">Integrated with</h6>

      <div className="partner-container">
        <div className="partner">
          <img src={WPTImg} className="wpt" alt="WPT" />
        </div>
        <div className="partner">
          <img src={WePokerImg} className="we-poker" alt="we poker" />
        </div>
        <div className="partner">
          <img src={PokerKingImg} className="poker-king" alt="poker king" />
        </div>
      </div>
      {isBreakPoint ? (
        <Slider {...CarouselSettings}>{CardsList}</Slider>
      ) : (
        <div className="points-container">{CardsList}</div>
      )}
    </StyledPartnerSection>
  );
};

export default PartnerSection;

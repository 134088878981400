import styled from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';
import { StyledTextImageSection } from '../../style';

export const StyledCryptoTradingSection = styled(StyledTextImageSection)`
  padding-top: 20rem;
  padding-bottom: 9rem;
  background: ${({ theme }) => theme.palette.common.white};

  @media ${deviceQuery.tablet} {
    padding-top: 7.2rem;
    padding-bottom: 0;
  }

  .subsection-container {
    @media ${deviceQuery.tablet} {
      flex-direction: column-reverse;
    }

    .image-subsection {
      @media ${deviceQuery.tablet} {
        padding-top: 0;
      }

      & > div {
        overflow: visible;
      }

      .image-wrapper {
        width: 57.6rem;
        max-width: 100%;
        margin-left: auto;
        margin-right: 0;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 85%;
          padding-top: 85%;
          aspect-ratio: 1;
          bottom: 21%;
          left: -8px;
          background: ${({ theme }) => theme.palette.primary.main};
          border-radius: 50%;
        }

        .image {
          z-index: 1;
        }

        @media ${deviceQuery.tablet} {
          margin: auto;

          &:before {
            width: 96.6%;
            padding-top: 96.6%;
            bottom: 29.3%;
            left: 2.5%;
          }
        }

        @media ${deviceQuery.mobile} {
          width: 33.4rem;
        }
      }
    }
  }
`;

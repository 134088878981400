import React, { useState, useCallback, useRef } from 'react';

import ROUTES from '../../constants/routes';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

import { StyledFooterWrapper } from './style';
import AppLogo from '../../assets/app-logos/appLogo';
import { Nav, NavItem } from 'react-bootstrap';
import { NavHashLink } from 'react-router-hash-link';
import UpArrowButton from '../UpArrowButton/UpArrowButton';
import { useTheme } from 'styled-components';
import useMediaQuery from '../../hooks/useMediaQuery';
import { deviceQuery } from '../../styles/mediaSizes';
import { convertRemToPixel } from '../../common/styleHelpers';
import { scrollWithOffset } from '../../common/navHelpers';

const NAV_LINKS = [
  {
    label: 'Home',
    linkProps: {
      to: ROUTES.TOP,
    },
  },
  {
    label: 'How To',
    linkProps: {
      to: ROUTES.HOW_TO,
    },
  },
  {
    label: 'NFT Avatars',
    linkProps: {
      to: ROUTES.NFT_AVATARS,
    },
  },
  {
    label: 'Discover',
    linkProps: {
      to: ROUTES.DISCOVER,
    },
  },
  {
    label: 'Crypto Trading',
    linkProps: {
      to: ROUTES.CRYPTO_TRADING,
    },
  },
  {
    label: 'iGaming',
    linkProps: {
      to: ROUTES.I_GAMING,
    },
  },
  {
    label: 'Security',
    linkProps: {
      to: ROUTES.SECURITY,
    },
  },
];

const TNC_NAV_LINKS = [
  {
    label: 'Terms of Use',
    linkProps: {
      to: ROUTES.TERMS_OF_USE,
    },
  },
  {
    label: 'Privacy Policy',
    linkProps: {
      to: ROUTES.PRIVACY_POLICY,
    },
  },
];

const Footer = () => {
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const footerRef = useRef(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(deviceQuery.tablet);

  const scrollWithResponsiveOffset = useCallback(
    (el) => {
      const offset = isTablet
        ? theme.layout.header.heightMobile
        : theme.layout.header.height;

      scrollWithOffset(el, convertRemToPixel(offset));
    },
    [theme, isTablet]
  );

  const toggleFooterVisiblityOnIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      setIsFooterVisible(entry.isIntersecting);
    });
  }, []);

  useIntersectionObserver({
    targetRef: footerRef,
    callback: toggleFooterVisiblityOnIntersection,
    options: { rootMargin: '0px 0px -20px 0px' },
  });

  return (
    <StyledFooterWrapper ref={footerRef}>
      <div className="footer-content">
        <div className="row-1">
          <NavHashLink className="logo-container" to={ROUTES.HOME}>
            <AppLogo />
          </NavHashLink>
          <Nav className="footer-nav">
            {NAV_LINKS.map((navLink) => (
              <NavItem key={navLink.label}>
                <NavHashLink
                  smooth
                  scroll={scrollWithResponsiveOffset}
                  className="nav-link"
                  {...navLink.linkProps}
                >
                  {navLink.label}
                </NavHashLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <div className="row-2">
          <span className="copyright-text">
            Copyright © 2022 Kashxa Limited. All rights reserved.
          </span>
          <Nav className="footer-nav">
            {TNC_NAV_LINKS.map((navLink) => (
              <NavItem key={navLink.label}>
                <NavHashLink className="nav-link" {...navLink.linkProps}>
                  {navLink.label}
                </NavHashLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </div>
      <UpArrowButton visible={isFooterVisible} />
    </StyledFooterWrapper>
  );
};

Footer.propTypes = {};

export default Footer;

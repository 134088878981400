import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';

export const StyledButton = styled.button`
  color: ${({ theme, $outline }) =>
    `${$outline ? theme.palette.text.primary : theme.palette.primary.text}`};
  background-color: ${({ theme, $outline }) =>
    `${!$outline ? theme.palette.primary?.main : 'transparent'}`};

  border: ${({ theme, $outline }) =>
      !$outline ? 'transparent' : theme.palette.common.border.light}
    solid 2px;

  ${(props) =>
    props.$round &&
    css`
      border-radius: 1.6rem;
    `}

  width: ${(props) => (props.$width ? props.$width : 'max-content')};
  padding: 1.2rem 2.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    box-shadow: none;
    background-color: ${({ theme, $outline }) =>
      !$outline ? theme.palette.primary.hover : theme.palette.common.hover};
    color: ${({ theme, $outline }) =>
      !$outline ? theme.palette.primary.text : theme.palette.text.primary};
  }

  &:not(:disabled):focus:active:hover,
  &.btn:not(:disabled):focus:active {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;

    &:focus:active:hover,
    &:focus:active {
    }
  }
  &:focus-visible {
    outline: none;
  }
`;

StyledButton.propTypes = {
  $round: PropTypes.bool,
  $outline: PropTypes.bool,
};

import React, { useState, useEffect, useCallback } from 'react';
import AppLogo from '../../assets/app-logos/appLogo';
import { StyledHeader } from './style';
import { Nav, NavItem } from 'react-bootstrap';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import ROUTES from '../../constants/routes';
import Button from '../Button';
import AppStoreIcon from '../../assets/icons/appStoreIcon';
import PlayStoreIcon from '../../assets/icons/playStoreIcon';
import useMediaQuery from '../../hooks/useMediaQuery';
import { deviceQuery } from '../../styles/mediaSizes';
import MenuIcon from '../../assets/icons/menuIcon';
import CloseIcon from '../../assets/icons/closeIcon';
import Drawer from '../drawer';
import { scrollWithOffset } from '../../common/navHelpers';
import { useTheme } from 'styled-components';
import { convertRemToPixel } from '../../common/styleHelpers';

const NAV_LINKS = [
  {
    label: 'Home',
    linkProps: {
      to: ROUTES.TOP,
    },
  },
  {
    label: 'How To',
    linkProps: {
      to: ROUTES.HOW_TO,
    },
  },
  {
    label: 'NFT Avatars',
    linkProps: {
      to: ROUTES.NFT_AVATARS,
    },
  },
  {
    label: 'Discover',
    linkProps: {
      to: ROUTES.DISCOVER,
    },
  },
  {
    label: 'Crypto Trading',
    linkProps: {
      to: ROUTES.CRYPTO_TRADING,
    },
  },
  {
    label: 'iGaming',
    linkProps: {
      to: ROUTES.I_GAMING,
    },
  },
  {
    label: 'Security',
    linkProps: {
      to: ROUTES.SECURITY,
    },
  },
];

const Header = () => {
  const isBreakPointDesktop = useMediaQuery(deviceQuery.desktopM);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();

  const scrollWithResponsiveOffset = useCallback(
    (el) => {
      const offset = theme.layout.header.height;

      scrollWithOffset(el, convertRemToPixel(offset));
    },
    [theme]
  );

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    const bodyEl = document?.querySelector('body');
    if (bodyEl) {
      bodyEl.style.overflow =
        isDrawerOpen && isBreakPointDesktop ? 'hidden' : '';
    }
  }, [isDrawerOpen, isBreakPointDesktop]);

  return (
    <>
      <StyledHeader className="header">
        <NavLink className="header-logo" to={ROUTES.HOME}>
          <AppLogo />
        </NavLink>

        {!isBreakPointDesktop ? (
          <Nav className="header-nav">
            {NAV_LINKS.map((navLink, index) => (
              <NavItem key={index}>
                <NavLink
                  smooth
                  scroll={scrollWithResponsiveOffset}
                  className="nav-link"
                  {...navLink.linkProps}
                >
                  {navLink.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        ) : null}

        {!isBreakPointDesktop ? (
          <div className="download-btn-container">
            <Button outline className="download-btn">
              <AppStoreIcon /> Get
            </Button>
            <Button outline className="download-btn">
              <PlayStoreIcon /> Get
            </Button>
          </div>
        ) : (
          <div className="drawer-btn-container">
            <span
              role="button"
              onClick={toggleDrawer}
              onKeyPress={toggleDrawer}
              tabIndex={0}
            >
              {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
            </span>
          </div>
        )}
      </StyledHeader>
      {isBreakPointDesktop && (
        <Drawer
          navItems={NAV_LINKS}
          show={isDrawerOpen}
          onClose={toggleDrawer}
        />
      )}
    </>
  );
};

export default Header;

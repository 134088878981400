import styled from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';
import { StyledTextImageSection } from '../../style';

export const StyledDiscoverSection = styled(StyledTextImageSection)`
  padding-top: 13.8rem;
  padding-bottom: 13.9rem;

  @media ${deviceQuery.tablet} {
    padding-top: 7rem;
    padding-bottom: 8.7rem;
  }

  .text-subsection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .image-subsection {
    .mobile-image {
      position: relative;
      width: 46rem;
      max-width: 100%;
      margin: auto;
      .hover-image {
        position: absolute;
        bottom: 9%;
        left: 0;
      }
    }
  }
`;

import React from 'react';

import StoreButtonsContainer from '../../../../components/StoreButtonsContainer';
import { StyledGetGamaWalletSection } from './style';

const GetGamaWalletSection = () => {
  return (
    <StyledGetGamaWalletSection>
      <div className="content">
        <div className="section-title">Get your Gama Wallet now!</div>
        <div className="section-body">
          Join thousands of other gamers and download the world’s leading
          iGaming wallet
        </div>
        <StoreButtonsContainer outline className="store-button-container" />
      </div>
    </StyledGetGamaWalletSection>
  );
};

GetGamaWalletSection.propTypes = {};

export default GetGamaWalletSection;

import styled from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';

export const StyledIConnectSection = styled.section`
  background-color: ${(props) => props.theme.palette.common.background.dark};

  .section-content-wrapper {
    max-width: ${(props) => props.theme.layout.maxWidth};
    width: 100%;
    margin: auto;
    padding-top: 15.1rem;
    padding-bottom: 15.1rem;
    position: relative;
    @media only screen {
      @media ${deviceQuery.laptopL} {
        padding-top: 7.4rem;
        padding-bottom: 7.4rem;
      }
    }
    .content {
      text-align: left;
      color: ${(props) => props.theme.palette.primary.text};
      @media only screen {
        @media ${deviceQuery.laptopL} {
          text-align: center;
        }
      }
      .section-title {
        font-size: ${(props) => props.theme.typography.title2};
        font-weight: 800;
        line-height: 5.1rem;
        @media only screen {
          @media ${deviceQuery.tablet} {
            font-size: 3.6rem;
            line-height: 4.45rem;
          }
        }
      }
      .section-para {
        opacity: 0.8;
        line-height: 3rem;
        font-size: ${(props) => props.theme.typography.body1};
        font-weight: 400;
        @media only screen {
          @media ${deviceQuery.tablet} {
            font-size: ${(props) => props.theme.typography.body2};
            line-height: 2.4rem;
          }
        }
        &.para-1 {
          margin-top: 2.4rem;
          margin-bottom: 2rem;
          @media only screen {
            @media ${deviceQuery.tablet} {
              margin-bottom: 1.6rem;
            }
          }
        }
      }
    }
    .images-col {
      position: static;
      @media only screen {
        @media ${deviceQuery.laptopL} {
          position: relative;
          padding-top: 6rem;
        }
        @media ${deviceQuery.mobileSM} {
          overflow: hidden;
        }
      }
      .game-table {
        top: -1rem;
        bottom: -1rem;
        right: 13%;
        position: absolute;
        @media only screen {
          @media ${deviceQuery.laptopL} {
            position: relative;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 10;
            max-width: 45.5rem;
            margin: auto;
          }
        }
        .game-table-image-wrapper {
          height: 100%;
          width: auto;
          overflow: visible;
          .image-wrapper {
            height: inherit;
            .game-table-image {
              height: inherit;
              width: auto;
              transform: translateY(2rem) scale(1.3);
              @media only screen {
                @media ${deviceQuery.laptopL} {
                  transform: none;
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }
      }
      .avatar-image-container {
        position: absolute;
        &.left {
          width: 10vw;
          max-width: 20.1rem;
          min-width: 14.4rem;
          left: 41.6%;
          top: 11rem;
          @media only screen {
            @media ${deviceQuery.laptopL} {
              top: 4rem;
              left: unset;
              right: 60%;
            }
            @media ${deviceQuery.tablet} {
              right: 65%;
            }
          }
        }
        &.right {
          width: 8vw;
          max-width: 18rem;
          min-width: 13.1rem;
          right: 1.5rem;
          bottom: 10rem;
          @media only screen {
            @media ${deviceQuery.laptopL} {
              bottom: 0;
              right: unset;
              left: 60%;
            }
            @media ${deviceQuery.tablet} {
              left: 65%;
            }
          }
        }

        .image-wrapper {
          z-index: 2;
        }

        .background-circle {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          padding-top: 100%;
          border-radius: 50%;
          background-color: #c4c4c4;
          opacity: 0.1;
        }
      }
    }
  }
`;

import styled from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';

export const StyledHeroSection = styled.section`
  max-width: 192rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 8rem;
  margin: auto;

  @media only screen {
    @media ${deviceQuery.tablet} {
      padding-top: 4rem;
    }
  }

  .section-heading {
    font-size: ${(props) => props.theme.typography.title1};
    color: ${(props) => props.theme.palette.text.primary};
    font-weight: 800;
    line-height: 6.5rem;
    max-width: 78rem;
    @media only screen {
      @media ${deviceQuery.tablet} {
        max-width: 100%;
        font-size: ${(props) => props.theme.typography.title2};
        line-height: 4.4rem;
        padding: 0 0.2rem;
      }
    }
  }

  .section-body {
    font-size: ${(props) => props.theme.typography.body1};
    line-height: 3rem;
    color: ${(props) => props.theme.palette.text.secondary};
    max-width: 50rem;
    margin: 3rem auto 5rem;
    @media only screen {
      @media ${deviceQuery.tablet} {
        font-size: ${(props) => props.theme.typography.body2};
        line-height: 2rem;
        padding: 0 0.2rem;
        margin: 1.6rem auto 3.4rem;
      }
    }
  }

  .hero-images {
    margin-top: 9.5rem;
    @media only screen {
      @media ${deviceQuery.tablet} {
        margin-top: 0;
        padding-top: 12.2rem;
        position: relative;
        align-self: stretch;
        overflow: hidden;
      }
    }
    .device-image-container {
      max-width: 100%;
      width: 40.5rem;
      height: 42.3rem;
      position: relative;
      z-index: 2;
      @media only screen {
        @media ${deviceQuery.tablet} {
          width: 25rem;
          height: 25.9rem;
          margin: auto;
        }
        @media ${deviceQuery.mobile} {
          width: 18rem;
          height: 20.7rem;
        }
      }
      .device-image {
        width: 100%;
        height: auto;
        transform: translateX(-25px) scale(1.4);
        @media ${deviceQuery.mobile} {
          transform: translateX(-10px) scale(1.7);
        }
      }
    }
    .avatar-images {
      position: absolute;
      &.left-char-1 {
        max-width: 36.4rem;
        min-width: 12.3rem;
        width: 25vw;
        top: 20%;
        left: 5%;
      }

      &.left-char-2 {
        max-width: 22.2rem;
        min-width: 9rem;
        width: 15vw;
        bottom: 5%;
        left: 18%;
      }

      &.right-char-1 {
        max-width: 29.5rem;
        min-width: 8.6rem;
        width: 20vw;
        top: 10%;
        right: 2%;
      }

      &.right-char-2 {
        max-width: 26.5rem;
        min-width: 9rem;
        width: 18vw;
        bottom: 15%;
        right: 12%;
      }

      @media only screen {
        @media ${deviceQuery.desktop} {
          &.left-char-1 {
            width: 20vw;
            top: 25%;
            left: 1%;
          }

          &.left-char-2 {
            width: 13vw;
            bottom: 5%;
            left: 12%;
          }

          &.right-char-1 {
            width: 18vw;
            top: 18%;
            right: 2%;
          }

          &.right-char-2 {
            width: 15vw;
            bottom: 15%;
            right: 12%;
          }
        }
        @media ${deviceQuery.laptopS} {
          &.left-char-1 {
            top: 47%;
          }

          &.left-char-2 {
            width: 13vw;
            bottom: 5%;
            left: 12%;
          }

          &.right-char-1 {
            width: 16vw;
            top: 42%;
            right: 1%;
          }

          &.right-char-2 {
            width: 15vw;
            bottom: 10%;
            right: 6%;
          }
        }
        @media ${deviceQuery.tablet} {
          &.left-char-1 {
            top: 15%;
            left: 2%;
          }

          &.left-char-2 {
            bottom: 5%;
            left: 10%;
          }

          &.right-char-1 {
            top: 10%;
            right: 10%;
          }

          &.right-char-2 {
            bottom: 12%;
            right: 5%;
          }
        }
        @media ${deviceQuery.mobileSM} {
          &.left-char-1 {
            top: 12%;
            left: 2%;
          }

          &.left-char-2 {
            bottom: 5%;
            left: -2%;
          }

          &.right-char-1 {
            top: 12%;
            right: 10%;
          }

          &.right-char-2 {
            bottom: 12%;
            right: 0;
          }
        }
        @media ${deviceQuery.mobile} {
          &.right-char-1 {
            right: 8%;
          }
          &.right-char-2 {
            right: -5%;
          }
        }
      }
    }
  }
`;

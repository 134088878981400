import styled from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const StyledTextImageSection = styled.section`
  padding-left: 7rem;
  padding-right: 7rem;
  width: 100%;
  margin: auto;

  @media ${deviceQuery.tablet} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .subsection-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 120rem;
    max-width: 100%;
    flex-wrap: wrap;

    .subsection {
      width: 50%;
      flex-shrink: 0;

      @media ${deviceQuery.tablet} {
        width: 100%;
      }
    }

    .right-subsection {
      padding-left: 7rem;

      @media ${deviceQuery.tablet} {
        padding-left: 0;
        width: 100%;
      }
    }

    .text-subsection {
      @media ${deviceQuery.tablet} {
        text-align: center;
      }

      .data-wrapper {
        width: 48rem;
        max-width: 100%;

        @media ${deviceQuery.tablet} {
          margin: auto;
        }
      }
    }

    .image-subsection {
      @media ${deviceQuery.tablet} {
        padding-top: 5rem;
      }
    }
  }
`;

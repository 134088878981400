import styled from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const StyledStoreButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  .playstore-btn {
    padding: 2rem 3rem;
  }
  .appstore-btn {
    margin-right: 2rem;
    padding: 1.7rem 3rem;
  }
  .store-btns {
    @media only screen {
      @media ${deviceQuery.tablet} {
        &.playstore-btn,
        &.appstore-btn {
          padding: 1rem 2rem;
        }
        &.appstore-btn {
          padding-top: 0.7rem;
          padding-bottom: 0.7rem;
        }
        .icon {
          width: 11rem;
        }
      }
    }
  }
`;

import styled from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const StyledDrawer = styled.div`
  width: 100vw;
  height: calc(100vh - ${({ theme }) => theme.layout.header.height});
  background: ${({ theme }) => theme.palette.common.background.light};
  position: fixed;
  left: -100vw;
  top: ${({ theme }) => theme.layout.header.height};
  transition: left 0.3s ease-in-out;
  z-index: 999;
  padding: 0 7rem;
  display: flex;
  flex-direction: column;

  &.entered {
    left: 0;
  }

  @media ${deviceQuery.tablet} {
    height: calc(100vh - ${({ theme }) => theme.layout.header.heightMobile});
    top: ${({ theme }) => theme.layout.header.heightMobile};
    padding: 0 1.6rem;
  }

  .drawer-nav {
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    flex-wrap: nowrap;

    .nav-item {
      margin: 0.9rem 0;

      .nav-link {
        color: ${({ theme }) => theme.palette.text.primary};
        padding: 0.7rem 1.6rem;
        border-radius: 1rem;
        line-height: 2rem;

        @media (max-width: 1400px) {
          padding: 0.6rem 1.4rem;
        }

        &:hover {
          background-color: ${({ theme }) => theme.palette.common.hover};
        }
      }
    }
  }

  .download-btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 3rem -1rem 2rem;

    .download-btn {
      margin: 1rem;
      padding: 1rem 2.5rem;

      .icon {
        width: 11rem;
      }
    }
  }
`;

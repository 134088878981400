import React from 'react';
import PropTypes from 'prop-types';

import NftCard from '../../../../assets/images/howToConnectSection/nftCard.png';

import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import { StyledHowToConnectionSection, StyledStepCard } from './style';
import CurvedArrow from '../../../../assets/icons/curvedArrow';
import { WALLET_PAGE_SECTIONS } from '../../../../constants/appConstants';

const CONNECT_STEPS = [
  {
    number: 1,
    title: 'Download',
    body: 'Download the Gama Wallet.',
  },
  {
    number: 2,
    title: 'Sign In',
    body: 'Sign-in with your existing Gaming account credentials ',
  },
  {
    number: 3,
    title: 'Connect',
    body: 'Connect your NFT avatar and start enjoying the associated perks & access.',
  },
];

const HowToConnectSection = () => {
  return (
    <StyledHowToConnectionSection id={WALLET_PAGE_SECTIONS.HOW_TO}>
      <div className="section-title">How to connect your avatar</div>
      <div className="steps-container">
        {CONNECT_STEPS.map((step, i, arr) => (
          <StepCard
            key={step.title}
            {...step}
            arrow={arr.length - 1 !== i}
            inverted={i % 2 === 1}
          />
        ))}
      </div>
      <div className="image-container">
        <ImageComponent
          src={NftCard}
          aspectRatio={0.72}
          className="image-wrapper"
        />
        <span className="sphere"></span>
      </div>
    </StyledHowToConnectionSection>
  );
};

HowToConnectSection.propTypes = {};

function StepCard({ number, title, body, arrow, inverted }) {
  return (
    <StyledStepCard className="step" $invertedArrow={inverted}>
      <div className="number">
        <span>{number}</span>{' '}
      </div>
      <div className="title">{title}</div>
      <p className="body">{body}</p>
      {arrow && (
        <div className="arrow-container">
          <CurvedArrow className="arrow-icon" />
        </div>
      )}
    </StyledStepCard>
  );
}

StepCard.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  body: PropTypes.string,
  arrow: PropTypes.bool,
  inverted: PropTypes.bool,
};

export default HowToConnectSection;

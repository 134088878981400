import React from 'react';

const CurvedArrow = (props) => {
  return (
    <svg
      width="256"
      height="100"
      viewBox="0 0 256 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_868_61812)">
        <path
          d="M9 34.5835C9 34.5835 110.605 -21.9999 236.626 44.7139"
          stroke="#8C97AC"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0 11"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M234.541 51.4755L233.254 49.0897L240.278 46.0126L236.637 39.2636L239.12 38.1759L243.404 46.1178C243.759 46.7766 243.492 47.5541 242.806 47.8545L234.541 51.4755Z"
          fill="#8C97AC"
        />
      </g>
      <defs>
        <clipPath id="clip0_868_61812">
          <rect
            width="256"
            height="100"
            fill="white"
            transform="matrix(1 0 0 -1 0 100)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

CurvedArrow.propTypes = {};

export default CurvedArrow;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { NavHashLink as NavLink } from 'react-router-hash-link';
import { StyledDrawer } from './style';
import Transition from 'react-transition-group/Transition';
import { Nav, NavItem } from 'react-bootstrap';
import Button from '../Button';
import AppStoreDownloadIcon from '../../assets/icons/appStoreDownloadIcon';
import PlayStoreDownloadIcon from '../../assets/icons/playStoreDownloadIcon';
import { scrollWithOffset } from '../../common/navHelpers';
import { convertRemToPixel } from '../../common/styleHelpers';
import { useTheme } from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';
import useMediaQuery from '../../hooks/useMediaQuery';

const Drawer = ({ show, onClose, navItems, onLinkClick }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(deviceQuery.tablet);

  const linkClickHandler = (data) => {
    onClose && onClose();
    onLinkClick && onLinkClick(data);
  };

  const scrollWithResponsiveOffset = useCallback(
    (el) => {
      const offset = isTablet
        ? theme.layout.header.heightMobile
        : theme.layout.header.height;

      scrollWithOffset(el, convertRemToPixel(offset));
    },
    [theme, isTablet]
  );

  return (
    <Transition in={show} timeout={100} mountOnEnter>
      {(state) => (
        <StyledDrawer
          xs={'auto'}
          className={`drawer-menu ${state}`}
          data-is-sidebar-collapsed={show}
        >
          <Nav className="drawer-nav">
            {navItems?.map((navLink, index) => (
              <NavItem key={index}>
                <NavLink
                  smooth
                  scroll={scrollWithResponsiveOffset}
                  className="nav-link"
                  onClick={() => linkClickHandler(navLink)}
                  {...navLink.linkProps}
                >
                  {navLink.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <div className="download-btn-container">
            <Button className="download-btn">
              <AppStoreDownloadIcon />
            </Button>

            <Button className="download-btn">
              <PlayStoreDownloadIcon />
            </Button>
          </div>
        </StyledDrawer>
      )}
    </Transition>
  );
};

Drawer.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLinkClick: PropTypes.func,
};

export default Drawer;

import React from 'react';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import { StyledDiscoverSection } from './style';
import DiscoverImage from '../../../../assets/images/discoverSection/discover.png';
import DiscoverMobilePart1Image from '../../../../assets/images/discoverSection/discoverMobilePart1.png';
import DiscoverMobilePart2Image from '../../../../assets/images/discoverSection/discoverMobilePart2.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { deviceQuery } from '../../../../styles/mediaSizes';
import { WALLET_PAGE_SECTIONS } from '../../../../constants/appConstants';

const DiscoverSection = () => {
  const isTablet = useMediaQuery(deviceQuery.tablet);

  return (
    <StyledDiscoverSection id={WALLET_PAGE_SECTIONS.DISCOVER}>
      <div className="subsection-container">
        <div className="subsection text-subsection left-subsection">
          <div className="data-wrapper">
            <h6 className="section-title">Discover the best NFT Games</h6>
            <p className="section-desc">
              We have the largest selection of decentralized apps (dApps) for
              the iGaming metaverse
            </p>
          </div>
        </div>
        <div className="subsection image-subsection right-subsection">
          {isTablet ? (
            <div className="mobile-image">
              <ImageComponent src={DiscoverMobilePart1Image} freeSize />
              <ImageComponent
                src={DiscoverMobilePart2Image}
                freeSize
                className="hover-image"
              />
            </div>
          ) : (
            <ImageComponent src={DiscoverImage} freeSize />
          )}
        </div>
      </div>
    </StyledDiscoverSection>
  );
};

export default DiscoverSection;

import React from 'react';
import CryptoTradingSection from './components/cryptoTradingSection';
import DiscoverSection from './components/discoverSection';

import HeroSection from './components/HeroSection';
import HowToConnectSection from './components/HowToConnectSection';
import NftAvatarsSection from './components/nftAvatarsSection';
import PartnerSection from './components/partnerSection';
import SecuritySection from './components/securitySection';
import IGamingSection from './components/IGamingSection';
import GetGamaWalletSection from './components/GetGamaWalletSection';

const GamaWalletHome = () => {
  return (
    <>
      <HeroSection />
      <PartnerSection />
      <HowToConnectSection />
      <NftAvatarsSection />
      <DiscoverSection />
      <CryptoTradingSection />
      <IGamingSection />
      <SecuritySection />
      <GetGamaWalletSection />
    </>
  );
};

export default GamaWalletHome;

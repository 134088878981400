import { createGlobalStyle } from 'styled-components';
import { deviceQuery } from './mediaSizes';

const GlobalStyles = createGlobalStyle`
html {
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 62.5%;
  
  body{
    font-size: ${({ theme }) => theme.typography.body1};
    background-color:${({ theme }) => theme.palette.common.background.light} ;
    color: ${({ theme }) => theme.palette.text.primary};

    p{
      font-size: ${({ theme }) => theme.typography.body1};
     
      @media ${deviceQuery.tablet}{
        font-size: ${({ theme }) => theme.typography.body2};
      }
    }

    @media ${deviceQuery.tablet}{
      font-size: ${({ theme }) => theme.typography.body2};
      line-height:2.4rem;
      
      p{
        font-size: ${({ theme }) => theme.typography.body2};
      }
    }

  }

    #gama-wallet-body{
        background-color:${({ theme }) =>
          theme.palette.common.background.light} ;
          flex:auto;
        min-height: 100vh;
        display:flex;
        flex-direction: column;
    }

  .sidebar-underlay {
    display: none;
    transition: all 0.3s ease-in-out;
    position: fixed;
    z-index: 998;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    
    @media ${deviceQuery.desktopM} {
      &.entered {
        display: block;
      }
    }
  }
  .section-title {
    font-weight: 800;
    font-size: 4.2rem;
    line-height: 5.2rem;
    margin-bottom: 2.4rem;
  
    @media ${deviceQuery.tablet} {
      font-size: 3.6rem;
      line-height: 4.4rem;
    }
  }
  .section-desc {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-bottom: 2rem;
  }

}
`;

export default GlobalStyles;

import styled, { css } from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';

export const StyledHowToConnectionSection = styled.section`
  max-width: ${(props) => props.theme.layout.maxWidth};
  width: 100%;
  margin: auto;
  padding: 14rem 1rem 3rem;
  @media only screen {
    @media ${deviceQuery.tablet} {
      padding: 7.2rem 1rem 4.2rem;
    }
  }
  .section-title {
    font-size: ${(props) => props.theme.typography.title2};
    line-height: 5.2rem;
    font-weight: 800;
    text-align: center;
    color: ${(props) => props.theme.palette.text.primary};
    @media only screen {
      @media ${deviceQuery.tablet} {
        font-size: 3.6rem;
        line-height: 4.45rem;
      }
    }
  }
  .steps-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 11rem auto 3.2rem;
    @media only screen {
      @media ${deviceQuery.laptopL} {
        flex-direction: column;
        align-items: center;
        margin: 4rem auto 5rem;
      }
    }
    .step {
      max-width: 100%;
      width: 35rem;
      &:not(:first-child):not(:last-child) {
        margin-left: 5rem;
        margin-right: 5rem;
        @media only screen {
          @media ${deviceQuery.laptopL} {
            margin: 5rem 0;
          }
        }
      }
    }
  }
  .image-container {
    max-width: 63rem;
    margin: auto;
    position: relative;
    .image-wrapper {
      z-index: 2;
    }
    .sphere {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-55%, -55%);
      max-width: 52.6rem;
      width: 100%;
      background-color: ${(props) => props.theme.palette.common.sphere};
      border-radius: 50%;
      &::after {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
      }
      @media only screen {
        @media ${deviceQuery.tablet} {
          width: 90%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;

export const StyledStepCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .number {
    width: 7.6rem;
    height: 7.6rem;
    font-size: 4rem;
    line-height: 5.8rem;
    font-weight: 700;
    border-radius: 50%;
    background-color: ${(props) => props.theme.palette.common.sphere};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.palette.primary.text};
    @media only screen {
      @media ${deviceQuery.tablet} {
        width: 6.8rem;
        height: 6.8rem;
        font-size: 3.4rem;
      }
    }
  }

  .title {
    font-size: ${(props) => props.theme.typography.subTitle1};
    color: ${(props) => props.theme.palette.text.primary};
    line-height: 3.5rem;
    margin: 2.2rem auto 1.2rem;
    font-weight: 700;
  }

  .body {
    color: ${(props) => props.theme.palette.text.secondary};
    line-height: 3rem;
    text-align: center;
  }

  .arrow-container {
    position: absolute;
    left: calc(50% + 7.4rem);
    @media only screen {
      @media ${deviceQuery.laptopL} {
        display: none;
      }
    }
    .arrow-icon {
      ${({ $invertedArrow }) =>
        $invertedArrow &&
        css`
          transform: rotateX(180deg);
        `}
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

const MenuIcon = ({ className = '' }) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon menu-icon ${className}`}
    >
      <path
        d="M39.9171 10.8647C39.9171 10.8647 38.6311 4.70448 33.0721 2.53999C30.4585 1.49941 25.6878 1.04155 20.9999 1C16.312 1.04152 11.5413 1.49939 8.92773 2.49844C3.36878 4.66293 2.08282 10.8231 2.08282 10.8231C1.25319 14.3194 0.962641 17.8575 1.00427 20.9792C0.962894 24.101 1.21176 27.6806 2.08282 31.1353C2.08282 31.1353 3.36878 37.2955 8.92773 39.46C11.5414 40.459 16.3121 40.9585 20.9999 41C25.6878 40.9585 30.4586 40.5006 33.0721 39.46C38.6311 37.2955 39.9171 31.1353 39.9171 31.1353C40.7467 27.639 41.0372 24.1009 40.9956 20.9792C41.0373 17.899 40.7884 14.3193 39.9171 10.8647Z"
        fill="white"
        stroke="#E9EAF4"
        strokeWidth="1.5"
      />
      <rect x="12" y="13" width="18" height="2.5" fill="#2956D0" />
      <rect x="12" y="20" width="18" height="2.5" fill="#2956D0" />
      <rect x="12" y="27" width="18" height="2.5" fill="#2956D0" />
    </svg>
  );
};

MenuIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default MenuIcon;

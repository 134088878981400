import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useMemoCompare from '../../hooks/useMemoCompare';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { StyledImageComponentWrapper } from './style';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Spinner } from 'react-bootstrap';

const ImageComponent = ({
  src,
  imageClassName="",
  alt = '',
  aspectRatio = 1.36,
  freeSize = false,
  className = '',
  lazyLoadImageComponentProps: _lazyLoadImageComponentProps = {},
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const lazyLoadImageComponentProps = useMemoCompare(
    _lazyLoadImageComponentProps
  );

  const toggleLoading = () => setLoading((prev) => !prev);

  return (
    <StyledImageComponentWrapper
      aspectRatio={aspectRatio}
      freeSize={freeSize}
      className={className}
      {...props}
    >
      {loading && (
        <div className="loader-wrap">
          <Spinner animation="border" className="spinner" />
        </div>
      )}
      <LazyLoadImage
        src={src}
        wrapperClassName={'image-wrapper'}
        placeholder={<span className={'place-holder'}></span>}
        {...lazyLoadImageComponentProps}
        effect={'blur'}
        alt={alt}
        className={'image ' + imageClassName}
        beforeLoad={toggleLoading}
        afterLoad={toggleLoading}
      />
    </StyledImageComponentWrapper>
  );
};

ImageComponent.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  freeSize: PropTypes.bool,
  imageClassName: PropTypes.string,
  lazyLoadImageComponentProps: PropTypes.object,
};

export default React.memo(ImageComponent);

import React from 'react';
import PropTypes from 'prop-types';

const AppStoreDownloadIcon = ({ fillColor = 'currentColor' }) => {
  return (
    <svg
      width="147"
      height="43"
      viewBox="0 0 147 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon app-store-download-icon"
    >
      <path
        d="M25.2855 19.8004C25.2461 15.0936 29.1377 12.806 29.3087 12.7008C27.0999 9.49283 23.6816 9.04582 22.4851 9.00637C19.619 8.70398 16.8317 10.7287 15.3724 10.7287C13.8867 10.7287 11.6253 9.04582 9.20622 9.08526C6.09028 9.13785 3.1584 10.939 1.56756 13.7526C-1.73245 19.4717 0.726119 27.8861 3.89465 32.514C5.48549 34.7754 7.32614 37.3128 9.75841 37.2208C12.125 37.1288 13.019 35.7088 15.872 35.7088C18.7118 35.7088 19.5401 37.2208 22.0118 37.1682C24.5624 37.1288 26.1533 34.8937 27.6915 32.606C29.519 30.016 30.2553 27.4522 30.2816 27.3208C30.2158 27.2945 25.3381 25.4275 25.2855 19.8004Z"
        fill={fillColor}
      />
      <path
        d="M20.6227 5.96894C21.898 4.3781 22.7657 2.20877 22.5291 0C20.6884 0.0788847 18.3745 1.2753 17.0466 2.83985C15.8765 4.22033 14.8247 6.46854 15.0876 8.58528C17.1649 8.74305 19.2948 7.53348 20.6227 5.96894Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.4539 36.9223H55.4568L49.7728 19.3223H46.2695L40.6094 36.9223H43.5288L45.0898 31.7507H50.8095L52.4539 36.9223ZM48.8349 24.9943L50.3244 29.582H45.5938L47.0594 24.9943C47.4407 23.5405 47.7148 22.4324 47.8935 21.6816H47.9531C48.3821 23.4214 48.68 24.5177 48.8349 24.9943Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.2435 35.5402C69.4232 34.289 70.0071 32.585 70.0071 30.4282C70.0071 28.4859 69.5066 26.9368 68.4819 25.7452C67.4809 24.5655 66.2416 23.9697 64.7402 23.9697C62.8337 23.9697 61.4037 24.7324 60.4624 26.2457H60.4147L60.2598 24.2319H57.7812C57.8527 25.6618 57.8885 27.0441 57.8885 28.4025V42.0702H60.7126V35.4091C61.4514 36.6127 62.6549 37.2085 64.3351 37.2085C65.8842 37.2085 67.1949 36.6603 68.2435 35.5402ZM66.2996 27.4729C66.8596 28.2712 67.1337 29.2841 67.1337 30.5234C67.1337 31.8699 66.8358 32.9423 66.24 33.7645C65.6442 34.5748 64.822 34.9919 63.7614 34.9919C62.8558 34.9919 62.117 34.6702 61.557 34.0386C60.9969 33.4071 60.7109 32.6206 60.7109 31.7031V29.582C60.7109 29.3556 60.7586 29.0696 60.842 28.724C61.0088 27.9733 61.3902 27.3775 61.9502 26.9128C62.5222 26.46 63.1537 26.2217 63.8449 26.2217C64.8696 26.2217 65.6918 26.6387 66.2996 27.4729Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.8295 35.5402C84.0092 34.289 84.5931 32.585 84.5931 30.4282C84.5931 28.4859 84.0926 26.9368 83.0678 25.7452C82.0668 24.5655 80.8276 23.9697 79.3262 23.9697C77.4196 23.9697 75.9897 24.7324 75.0483 26.2457H75.0006L74.8457 24.2319H72.3672C72.4387 25.6618 72.4744 27.0441 72.4744 28.4025V42.0702H75.2985V35.4091C76.0373 36.6127 77.2409 37.2085 78.921 37.2085C80.4701 37.2085 81.7809 36.6603 82.8295 35.5402ZM80.8855 27.4729C81.4456 28.2712 81.7196 29.2841 81.7196 30.5234C81.7196 31.8699 81.4336 32.9423 80.8259 33.7645C80.242 34.5748 79.4079 34.9919 78.3474 34.9919C77.4418 34.9919 76.703 34.6702 76.1429 34.0386C75.5829 33.4071 75.2969 32.6206 75.2969 31.7031V29.582C75.2969 29.3556 75.3445 29.0696 75.428 28.724C75.6067 27.9733 75.9761 27.3775 76.5361 26.9128C77.1081 26.46 77.7397 26.2217 78.4308 26.2217C79.4556 26.2217 80.2778 26.6387 80.8855 27.4729Z"
        fill={fillColor}
      />
      <path
        d="M100.919 31.9893C100.919 33.4907 100.395 34.7061 99.3578 35.6475C98.2139 36.6723 96.6171 37.1847 94.5676 37.1847C92.6729 37.1847 91.1596 36.8153 90.0156 36.0884L90.671 33.7409C91.9103 34.4678 93.2687 34.8372 94.7463 34.8372C95.8068 34.8372 96.6409 34.5989 97.2248 34.1222C97.8206 33.6456 98.1185 33.0021 98.1185 32.2037C98.1185 31.4888 97.8683 30.893 97.3917 30.4044C96.9031 29.9159 96.0928 29.4631 94.9608 29.046C91.8507 27.8901 90.3135 26.21 90.3135 24.0055C90.3135 22.5637 90.8617 21.384 91.946 20.4545C93.0304 19.537 94.4722 19.0723 96.2715 19.0723C97.8683 19.0723 99.2029 19.3463 100.263 19.9064L99.5485 22.1943C98.5475 21.658 97.4274 21.384 96.1643 21.384C95.1753 21.384 94.3888 21.6342 93.8288 22.1109C93.364 22.5518 93.1257 23.0761 93.1257 23.7076C93.1257 24.3987 93.3998 24.9826 93.936 25.4235C94.4007 25.8406 95.2587 26.2934 96.498 26.7819C98.0113 27.3897 99.1195 28.1046 99.8344 28.9268C100.561 29.749 100.919 30.7738 100.919 31.9893Z"
        fill={fillColor}
      />
      <path
        d="M110.253 26.353H107.143V32.5136C107.143 34.0746 107.691 34.8611 108.787 34.8611C109.288 34.8611 109.705 34.8134 110.038 34.73L110.122 36.8749C109.562 37.0775 108.835 37.1847 107.929 37.1847C106.809 37.1847 105.951 36.8391 105.32 36.1599C104.688 35.4807 104.378 34.3368 104.378 32.74V26.3411H102.531V24.232H104.378V21.9083L107.143 21.0742V24.232H110.253V26.353Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.581 35.2779C123.689 34.0267 124.249 32.4299 124.249 30.4757C124.249 28.581 123.713 27.032 122.628 25.8403C121.496 24.6011 119.995 23.9814 118.136 23.9814C116.205 23.9814 114.668 24.613 113.524 25.8642C112.392 27.1154 111.82 28.724 111.82 30.6902C111.82 32.5729 112.368 34.1339 113.477 35.3613C114.585 36.5886 116.074 37.2083 117.933 37.2083C119.864 37.2083 121.413 36.5648 122.581 35.2779ZM120.578 27.5446C121.078 28.3787 121.328 29.3677 121.328 30.5355C121.328 31.7033 121.066 32.7042 120.554 33.5622C119.934 34.587 119.088 35.0994 118.016 35.0994C116.92 35.0994 116.062 34.5989 115.454 33.586C114.953 32.7638 114.703 31.7629 114.703 30.5951C114.703 29.3916 114.941 28.3787 115.454 27.5446C116.038 26.5317 116.908 26.0312 118.04 26.0312C119.136 26.0312 119.982 26.5317 120.578 27.5446Z"
        fill={fillColor}
      />
      <path
        d="M133.429 26.7104C133.155 26.6628 132.857 26.6389 132.535 26.6389C131.546 26.6389 130.772 27.0083 130.236 27.759C129.771 28.4144 129.533 29.2605 129.533 30.2614V36.9225H126.708V28.2238C126.708 26.7581 126.685 25.4235 126.625 24.2319H129.08L129.187 26.6628H129.27C129.568 25.8286 130.033 25.1494 130.676 24.6489C131.308 24.1961 131.987 23.9697 132.714 23.9697C132.976 23.9697 133.215 23.9936 133.417 24.0174L133.429 26.7104Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.927 31.25C145.999 30.9045 146.034 30.4755 146.034 29.975C146.034 28.3902 145.653 27.0675 144.902 25.9712C143.973 24.6128 142.579 23.9336 140.756 23.9336C138.897 23.9336 137.419 24.6128 136.323 25.9712C135.274 27.2463 134.75 28.843 134.75 30.7496C134.75 32.6919 135.31 34.2529 136.43 35.4087C137.55 36.5646 139.099 37.1485 141.066 37.1485C142.698 37.1485 144.116 36.8863 145.32 36.362L144.879 34.4078C143.854 34.801 142.71 35.0036 141.459 35.0036C140.327 35.0036 139.409 34.7057 138.694 34.1218C137.908 33.4664 137.503 32.5012 137.467 31.25H145.927ZM142.799 27.0801C143.18 27.6878 143.371 28.4147 143.347 29.2488L137.484 29.2369C137.568 28.3908 137.854 27.652 138.318 27.0443C138.89 26.2817 139.641 25.9004 140.559 25.9004C141.56 25.9004 142.31 26.2936 142.799 27.0801Z"
        fill={fillColor}
      />
      <path
        d="M43.6094 14.2054H45.9458C48.272 14.2054 49.68 12.7503 49.68 10.6529C49.68 8.54546 48.2108 7.08028 45.9152 7.08028H43.6094V14.2054ZM44.8439 13.1216V8.16411H45.9356C47.3946 8.16411 48.4047 9.20779 48.4047 10.6529C48.4047 12.0679 47.4048 13.1216 45.8744 13.1216H44.8439Z"
        fill={fillColor}
      />
      <path
        d="M54.4583 14.2857C56.5498 14.2857 58.1108 12.8105 58.1108 10.6429C58.1108 8.46517 56.5498 7 54.4583 7C52.2851 7 50.7955 8.54545 50.7955 10.6429C50.7955 12.7403 52.2851 14.2857 54.4583 14.2857ZM54.4583 13.1517C53.0401 13.1517 52.0708 12.0378 52.0708 10.6429C52.0708 9.24793 53.0401 8.134 54.4583 8.134C55.7948 8.134 56.8253 9.17769 56.8253 10.6429C56.8253 12.098 55.7948 13.1517 54.4583 13.1517Z"
        fill={fillColor}
      />
      <path
        d="M60.7875 14.2054H61.9404L63.4504 8.84652H63.4708L64.9502 14.2054H66.1031L68.3987 7.08028H67.0928L65.5318 12.2084H65.5113L64.1034 7.08028H62.7974L61.3792 12.2184H61.3588L59.808 7.08028H58.4918L60.7875 14.2054Z"
        fill={fillColor}
      />
      <path
        d="M69.6145 14.2054H70.849V9.17769H70.8694L74.3689 14.2054H75.6239V7.08028H74.3995V12.1983H74.3791L70.8388 7.08028H69.6145V14.2054Z"
        fill={fillColor}
      />
      <path
        d="M77.396 14.2054H81.9873V13.1216H78.6306V7.08028H77.396V14.2054Z"
        fill={fillColor}
      />
      <path
        d="M86.302 14.2857C88.3936 14.2857 89.9546 12.8105 89.9546 10.6429C89.9546 8.46517 88.3936 7 86.302 7C84.1288 7 82.6392 8.54545 82.6392 10.6429C82.6392 12.7403 84.1288 14.2857 86.302 14.2857ZM86.302 13.1517C84.8838 13.1517 83.9146 12.0378 83.9146 10.6429C83.9146 9.24793 84.8838 8.134 86.302 8.134C87.6386 8.134 88.6691 9.17769 88.6691 10.6429C88.6691 12.098 87.6386 13.1517 86.302 13.1517Z"
        fill={fillColor}
      />
      <path
        d="M90.3733 14.2054H91.6589L92.1996 12.67H95.1584L95.7094 14.2054H96.9949L94.281 7.08028H93.077L90.3733 14.2054ZM92.5771 11.6263L93.6688 8.53542H93.6892L94.7911 11.6263H92.5771Z"
        fill={fillColor}
      />
      <path
        d="M98.1204 14.2054H100.457C102.783 14.2054 104.191 12.7503 104.191 10.6529C104.191 8.54546 102.722 7.08028 100.426 7.08028H98.1204V14.2054ZM99.3549 13.1216V8.16411H100.447C101.906 8.16411 102.916 9.20779 102.916 10.6529C102.916 12.0679 101.916 13.1216 100.385 13.1216H99.3549Z"
        fill={fillColor}
      />
      <path
        d="M111.699 14.2857C113.791 14.2857 115.352 12.8105 115.352 10.6429C115.352 8.46517 113.791 7 111.699 7C109.526 7 108.037 8.54545 108.037 10.6429C108.037 12.7403 109.526 14.2857 111.699 14.2857ZM111.699 13.1517C110.281 13.1517 109.312 12.0378 109.312 10.6429C109.312 9.24793 110.281 8.134 111.699 8.134C113.036 8.134 114.066 9.17769 114.066 10.6429C114.066 12.098 113.036 13.1517 111.699 13.1517Z"
        fill={fillColor}
      />
      <path
        d="M116.802 14.2054H118.037V9.17769H118.057L121.557 14.2054H122.812V7.08028H121.587V12.1983H121.567L118.027 7.08028H116.802V14.2054Z"
        fill={fillColor}
      />
      <path
        d="M128.803 14.2054H130.038V8.16411H132.119V7.08028H126.753V8.16411H128.803V14.2054Z"
        fill={fillColor}
      />
      <path
        d="M133.322 14.2054H134.556V11.3453H137.821V14.2054H139.056V7.08028H137.821V10.2615H134.556V7.08028H133.322V14.2054Z"
        fill={fillColor}
      />
      <path
        d="M140.834 14.2054H145.609V13.1216H142.069V11.2149H144.752V10.1311H142.069V8.16411H145.395V7.08028H140.834V14.2054Z"
        fill={fillColor}
      />
    </svg>
  );
};

AppStoreDownloadIcon.propTypes = {
  fillColor: PropTypes.string,
};

export default AppStoreDownloadIcon;

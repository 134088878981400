import React from 'react';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import { StyledNftAvatarsSection } from './style';
import AvatarsImage from '../../../../assets/images/nFTAvatarsSection/avatars.png';
import { WALLET_PAGE_SECTIONS } from '../../../../constants/appConstants';

const NftAvatarsSection = () => {
  return (
    <StyledNftAvatarsSection id={WALLET_PAGE_SECTIONS.NFT_AVATARS}>
      <div className="subsection-container">
        <div className="subsection image-subsection left-subsection">
          <ImageComponent src={AvatarsImage} freeSize />
        </div>
        <div className="subsection text-subsection right-subsection">
          <div className="data-wrapper">
            <h6 className="section-title">Securely store your beloved NFTs</h6>
            <p className="section-desc">
              The Gama Wallet is your one-stop-shop to store all your
              Non-Fungible Tokens (NFTs) in one secure place
            </p>
            <p className="section-desc">
              You can access your beloved NFTs anytime you want inside your
              wallet. Purchases and sales are updated in real time
            </p>
          </div>
        </div>
      </div>
    </StyledNftAvatarsSection>
  );
};

export default NftAvatarsSection;

import styled from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const StyledErrorPage = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.text.primary};

  h2.title {
    font-size: ${(props) => props.theme.typography.title2};
    @media ${deviceQuery.tabletM} {
      font-size: ${(props) => props.theme.typography.subTitle2};
    }
  }

  .statusCode {
    font-size: 6.4rem;
    color: ${(props) => props.theme.palette.primary.main};
    @media ${deviceQuery.tabletM} {
      font-size: 5.2rem;
    }
  }
  .message {
    font-size: 2.4rem;
    @media ${deviceQuery.tabletM} {
      font-size: 1.8rem;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

const AppLogo = ({ fillColor = 'currentColor' }) => {
  return (
    <svg
      width="176"
      height="54"
      viewBox="0 0 176 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="logo app-logo"
    >
      <path
        d="M26.5332 40.2393V53.9744H29.4932V40.2393C29.0099 40.2921 28.5115 40.3223 28.0132 40.3223C27.5073 40.3223 27.0165 40.2921 26.5332 40.2393Z"
        fill={fillColor}
      />
      <path
        d="M28.9114 13.6823V0H27.1143V13.6823C27.4087 13.6596 27.7108 13.6521 28.0128 13.6521C28.3149 13.6521 28.6093 13.6672 28.9114 13.6823Z"
        fill={fillColor}
      />
      <path
        d="M29.9609 40.1788L33.525 53.5515L36.4774 52.7663L32.9133 39.3936C31.9695 39.7636 30.9879 40.0278 29.9609 40.1788Z"
        fill={fillColor}
      />
      <path
        d="M25.4155 13.9091L21.9345 0.740234L20.0996 1.22349L23.5881 14.4074C24.1771 14.196 24.7887 14.0299 25.4155 13.9091Z"
        fill={fillColor}
      />
      <path
        d="M33.1626 39.2872L40.1472 51.195L42.8655 49.6018L35.9036 37.7393C35.0579 38.3584 34.1367 38.8795 33.1626 39.2872Z"
        fill={fillColor}
      />
      <path
        d="M22.2141 14.9738L15.3805 3.08105L13.6514 4.07023L20.4925 15.9705C21.0362 15.6005 21.6176 15.2683 22.2141 14.9738Z"
        fill={fillColor}
      />
      <path
        d="M36.1299 37.5658L45.8857 47.3216L48.1812 45.0261L38.448 35.293C37.7684 36.1387 36.9907 36.9013 36.1299 37.5658Z"
        fill={fillColor}
      />
      <path
        d="M19.2613 16.9288L9.56592 7.2334L8.08594 8.71339L17.7964 18.4239C18.2495 17.8802 18.7328 17.3819 19.2613 16.9288Z"
        fill={fillColor}
      />
      <path
        d="M38.5615 35.1416L50.5977 41.9374L52.2438 39.0303L40.2605 32.2646C39.815 33.2916 39.2411 34.2581 38.5615 35.1416Z"
        fill={fillColor}
      />
      <path
        d="M17.0036 19.4657L5.15615 12.5566L4.05371 14.4444L15.9238 21.3686C16.2258 20.6965 16.5883 20.0623 17.0036 19.4657Z"
        fill={fillColor}
      />
      <path
        d="M40.3213 32.1222L53.5807 35.6409L54.4642 32.3185L41.2198 28.7998C41.0613 29.9626 40.7592 31.0726 40.3213 32.1222Z"
        fill={fillColor}
      />
      <path
        d="M15.4561 22.4861L2.12875 18.8994L1.53223 21.1043L14.8672 24.691C15.0031 23.9283 15.1994 23.1959 15.4561 22.4861Z"
        fill={fillColor}
      />
      <path
        d="M14.6781 26.9872C14.6781 26.587 14.6932 26.1869 14.7309 25.8018H0.859863V28.1803H14.7309C14.6932 27.7876 14.6781 27.3874 14.6781 26.9872Z"
        fill={fillColor}
      />
      <path
        d="M14.8666 29.2529L1.5166 32.7868L2.15088 35.1804L15.5085 31.6466C15.2215 30.8764 15.0101 30.076 14.8666 29.2529Z"
        fill={fillColor}
      />
      <path
        d="M39.9205 20.984L51.7755 14.1051L51.0657 12.8818L39.2183 19.7607C39.4674 20.1534 39.7015 20.5611 39.9205 20.984Z"
        fill={fillColor}
      />
      <path
        d="M15.8563 32.4844L3.95605 39.3708L5.24726 41.5983L17.14 34.7119C16.6416 34.0097 16.2112 33.2697 15.8563 32.4844Z"
        fill={fillColor}
      />
      <path
        d="M37.9577 18.107L47.5625 8.50218L46.4979 7.4375L36.8931 17.0423C37.2631 17.3745 37.618 17.737 37.9577 18.107Z"
        fill={fillColor}
      />
      <path
        d="M17.6829 35.4287L7.88184 45.2298L9.76957 47.1175L19.5707 37.3164C18.8835 36.7501 18.2493 36.1158 17.6829 35.4287Z"
        fill={fillColor}
      />
      <path
        d="M35.3674 15.8643L42.201 3.97911L40.8116 3.17871L33.978 15.0639C34.4537 15.3055 34.9219 15.5698 35.3674 15.8643Z"
        fill={fillColor}
      />
      <path
        d="M20.1826 37.7852L13.3188 49.7081L15.7125 51.0899L22.5763 39.167C21.723 38.7894 20.9226 38.3213 20.1826 37.7852Z"
        fill={fillColor}
      />
      <path
        d="M35.8205 1.20038L34.1744 0.754883L30.6255 13.9086C31.1843 14.0219 31.7355 14.1653 32.2716 14.3466L35.8205 1.20038Z"
        fill={fillColor}
      />
      <path
        d="M19.6392 52.7965L22.4028 53.529L25.9442 40.1638C24.9852 40.0128 24.064 39.7636 23.1805 39.4238L19.6392 52.7965Z"
        fill={fillColor}
      />
      <path
        d="M54.9997 25.2197H28.0127V28.7536H54.9997V25.2197Z"
        fill={fillColor}
      />
      <path
        d="M86.5525 29.5887C90.0324 29.5887 93.2569 28.5089 95.3641 26.7302V17.8054H86.0097V20.3145H92.5865V25.1739C90.8944 26.4444 88.8192 27.0478 86.5525 27.0478C81.4762 27.0478 78.124 23.3 78.124 18.1865C78.124 12.7871 81.6678 9.2934 86.4248 9.2934C89.0746 9.2934 91.3095 10.1509 93.1292 11.8978L95.0767 10.1827C92.6823 7.70534 89.5535 6.65723 86.4567 6.65723C79.6245 6.65723 75.2188 11.4849 75.2188 18.1865C75.2188 25.1739 80.0396 29.5887 86.5525 29.5887Z"
        fill={fillColor}
      />
      <path
        d="M100.376 29.4299H103.282L105.293 23.9353H115.414L117.457 29.4299H120.362L111.742 6.87955H108.965L100.376 29.4299ZM106.187 21.5214L110.305 10.2145H110.369L114.552 21.5214H106.187Z"
        fill={fillColor}
      />
      <path
        d="M126.198 29.4299H129.007V12.2472H129.039L136.414 24.8881H138.298L145.672 12.2472H145.736V29.4299H148.514V6.87955H145.736L137.404 21.4579H137.34L129.007 6.87955H126.198V29.4299Z"
        fill={fillColor}
      />
      <path
        d="M154.356 29.4299H157.262L159.273 23.9353H169.394L171.437 29.4299H174.342L165.722 6.87955H162.944L154.356 29.4299ZM160.167 21.5214L164.285 10.2145H164.349L168.531 21.5214H160.167Z"
        fill={fillColor}
      />
      <path
        d="M78.5939 49.0686L75.2188 39.5199H76.3798L79.08 47.4162L81.6721 39.4928H82.5497L85.1418 47.4162L87.842 39.5199H88.9625L85.5873 49.0686H84.6828L82.0906 41.3754L79.485 49.0686H78.5939Z"
        fill={fillColor}
      />
      <path
        d="M97.4672 49.0009L101.774 39.4521H102.773L107.08 49.0009H105.932L104.825 46.4952H99.6813L98.5608 49.0009H97.4672ZM100.1 45.5335H104.407L102.26 40.6982L100.1 45.5335Z"
        fill={fillColor}
      />
      <path
        d="M117.535 49.0009V39.5199H118.601V48.0121H123.921V49.0009H117.535Z"
        fill={fillColor}
      />
      <path
        d="M134.485 49.0009V39.5199H135.552V48.0121H140.871V49.0009H134.485Z"
        fill={fillColor}
      />
      <path
        d="M151.435 49.0009V39.5199H158.267V40.4951H152.502V43.7321H157.659V44.7073H152.502V48.0257H158.334V49.0009H151.435Z"
        fill={fillColor}
      />
      <path
        d="M171.569 49.0009V40.5086H168.396V39.5199H175.821V40.5086H172.649V49.0009H171.569Z"
        fill={fillColor}
      />
    </svg>
  );
};

AppLogo.propTypes = {
  fillColor: PropTypes.string,
};

export default AppLogo;

import React from 'react';

import Avatar1 from '../../../../assets/images/iGamingSection/avatar1.png';
import Avatar2 from '../../../../assets/images/iGamingSection/avatar2.png';
import GameTable from '../../../../assets/images/iGamingSection/gameTable.png';

import { StyledIConnectSection } from './style';
import { Container, Row, Col } from 'react-bootstrap';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import { WALLET_PAGE_SECTIONS } from '../../../../constants/appConstants';

const IGamingSection = () => {
  return (
    <StyledIConnectSection id={WALLET_PAGE_SECTIONS.I_GAMING}>
      <Container fluid className="section-content-wrapper">
        <Row>
          <Col xs={12} xl={5} className="content">
            <div className="section-title">
              Connect your <br />
              iGaming Account
            </div>
            <div className="section-para para-1">
              We make it easy to connect your existing iGaming accounts.
            </div>
            <div className="section-para para-2">
              Once you have synced your account you can benefit from future NFT
              airdrops and play-2-earn rewards that you can directly redeem
              inside your favorite iGaming platforms. Today we are integrated
              with PokerKing, WePoker and World Poker Tour Global. We will add
              more iGaming platforms in the future!
            </div>
          </Col>
          <Col xs={12} xl={7} className="images-col">
            <div className="avatar-image-container left">
              <ImageComponent
                src={Avatar1}
                aspectRatio={0.95}
                className="image-wrapper"
              />
              <span className="background-circle"></span>
            </div>
            <div className="game-table">
              <ImageComponent
                src={GameTable}
                aspectRatio={0.7}
                freeSize
                className="game-table-image-wrapper"
                imageClassName="game-table-image"
              />
            </div>
            <div className="avatar-image-container right">
              <ImageComponent
                src={Avatar2}
                aspectRatio={0.95}
                className="image-wrapper"
              />
              <span className="background-circle"></span>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledIConnectSection>
  );
};

IGamingSection.propTypes = {};

export default IGamingSection;

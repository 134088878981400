import styled from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const StyledHeader = styled.div`
  padding: 2rem 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  height: ${({ theme }) => theme.layout.header.height};
  z-index: 998;
  background: ${({ theme }) => theme.palette.common.background.light};

  @media ${deviceQuery.tablet} {
    padding: 2rem 1.6rem;
    height: ${({ theme }) => theme.layout.header.heightMobile};
  }

  .header-logo {
    color: ${({ theme }) => theme.palette.primary.main};
    width: 17.5rem;

    @media ${deviceQuery.tablet} {
      width: 12.9rem;
    }

    .logo {
      width: 100%;
    }
  }

  .header-nav {
    .nav-item {
      &:not(:first-child) {
        margin-left: 1.2rem;

        @media (max-width: 1400px) {
          margin-left: 0.8rem;
        }
      }

      .nav-link {
        color: ${({ theme }) => theme.palette.text.primary};
        padding: 0.7rem 1.6rem;
        border-radius: 1rem;
        line-height: 2rem;

        @media (max-width: 1400px) {
          padding: 0.6rem 1.4rem;
        }

        &:hover {
          background-color: ${({ theme }) => theme.palette.common.hover};
        }
      }
    }
  }

  .download-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .download-btn {
      background-color: ${({ theme }) => theme.palette.common.white};
      width: 7.9rem;
      height: 4rem;
      padding: 0.8rem 1.2rem;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 2.2rem;

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.text};
      }

      .icon {
        margin-right: 0.6rem;
        width: 2.2rem;
        height: 2.2rem;
      }

      &:not(:first-child) {
        margin-left: 2rem;
      }
    }
  }

  .drawer-btn-container {
    .icon {
    }
  }
`;

import React from 'react';
import { Route, Switch } from 'react-router';
import Footer from '../components/Footer';
import Header from '../components/header';
import GamaWalletHome from '../pages/home';
import ErrorPage from '../components/ErrorPage';
import ROUTES from '../constants/routes';

const AppRouter = () => {
  return (
    <div id="gama-wallet-body">
      <Header />
      <Switch>
        <Route path={ROUTES.HOME} exact component={GamaWalletHome} />
        <Route path={ROUTES.HOME} exact={false} component={ErrorPage} />
      </Switch>
      <Footer />
    </div>
  );
};

export default AppRouter;

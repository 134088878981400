import React from 'react';

import DeviceImageCropped from '../../../../assets/images/heroSection/deviceImageCropped.png';
import AvatarRightChar1 from '../../../../assets/images/heroSection/avatarRightChar1.png';
import AvatarRightChar2 from '../../../../assets/images/heroSection/avatarRightChar2.png';
import AvatarLeftChar1 from '../../../../assets/images/heroSection/avatarLeftChar1.png';
import AvatarLeftChar2 from '../../../../assets/images/heroSection/avatarLeftChar2.png';

import { StyledHeroSection } from './style';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import StoreButtonsContainer from '../../../../components/StoreButtonsContainer';

const AvatarImages = [
  {
    src: AvatarLeftChar1,
    className: 'left-char-1',
  },
  {
    src: AvatarLeftChar2,
    className: 'left-char-2',
  },
  {
    src: AvatarRightChar1,
    className: 'right-char-1',
  },
  {
    src: AvatarRightChar2,
    className: 'right-char-2',
  },
];

const HeroSection = () => {
  return (
    <StyledHeroSection>
      <div className="section-heading">The World’s Leading iGaming Wallet</div>
      <div className="section-body">
        Discover Play-2-Earn games, store your NFTs, and trade popular crypto.
        Let’s play!
      </div>
      <StoreButtonsContainer />
      <div className="hero-images">
        <div className="device-image-container">
          <img src={DeviceImageCropped} alt="device" className="device-image" />
        </div>
        {AvatarImages.map(({ src, className }) => (
          <div className={`avatar-images ${className}`} key={className}>
            <ImageComponent src={src} aspectRatio={1} />
          </div>
        ))}
      </div>
    </StyledHeroSection>
  );
};

HeroSection.propTypes = {};

export default HeroSection;

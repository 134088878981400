import React from 'react';
import { StyledSecuritySection } from './style';
import LevelImage from '../../../../assets/images/icons/level.svg';
import TickImage from '../../../../assets/images/icons/tick.svg';
import SuccessTickImage from '../../../../assets/images/icons/successTick.svg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { deviceQuery } from '../../../../styles/mediaSizes';
import { WALLET_PAGE_SECTIONS } from '../../../../constants/appConstants';

const LEVELS_DATA = [
  { name: 'Key/seed generation', level1: true, level2: false, level3: false },
  { name: 'Wallet creation', level1: true, level2: true, level3: true },
  { name: 'Key storage', level1: true, level2: false, level3: false },
  { name: 'Key usage', level1: true, level2: true, level3: false },
  { name: 'Key compromise Policy', level1: true, level2: true, level3: false },
  {
    name: 'Keyholder grant/revoke  Policies & Procedures',
    level1: true,
    level2: true,
    level3: true,
  },
  {
    name: 'Third-party Security  Audits/Pentests',
    level1: true,
    level2: false,
    level3: false,
  },
  {
    name: 'Data Sanitization Policy',
    level1: true,
    level2: true,
    level3: true,
  },
  { name: 'Proof of reserve', level1: true, level2: false, level3: false },
];

const SecuritySection = () => {
  const isTablet = useMediaQuery(deviceQuery.tablet);

  return (
    <StyledSecuritySection $active={0} id={WALLET_PAGE_SECTIONS.SECURITY}>
      <div className="section-head">
        <h6 className="section-title">
          Security and Protection through CCSS Compliance
        </h6>
        <p className="section-desc">
          Your assets are protected and secure. Our wallet is compliant with the
          leading crypto security protocol: Cryptocurrency Security Standard
          (CCSS)
        </p>

        <div className="levels-list-container">
          <div className="highlighter">
            {!isTablet && (
              <img src={SuccessTickImage} alt="" className="success-tick" />
            )}
          </div>
          <ul className="levels-list">
            <li>
              <div className="item-name heading">
                <img className="level-image" src={LevelImage} alt="" />
                <span>CryptoCurrency Security Standard </span>
              </div>

              <div className="level heading">
                <img className="level-image" src={LevelImage} alt="" />
                <span>Level 1</span>
              </div>
              <div className="level heading">
                <img className="level-image" src={LevelImage} alt="" />
                <span>Level 2</span>
              </div>
              <div className="level heading">
                <img className="level-image" src={LevelImage} alt="" />
                <span>Level 3</span>
              </div>
            </li>
            {LEVELS_DATA?.map(({ name, level1, level2, level3 }, i) => {
              const levels = [level1, level2, level3];
              return (
                <li key={`${name}-${i}`}>
                  <div className="item-name">{name}</div>
                  {levels?.map((item, i) => {
                    return (
                      <div key={i} className={`level level-${i + 1}`}>
                        {item && (
                          <img
                            src={TickImage}
                            alt="available"
                            className="tick-image"
                          />
                        )}
                      </div>
                    );
                  })}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </StyledSecuritySection>
  );
};

export default SecuritySection;

/**
 * a function to convert pixel to rem
 * @param {number} px
 * @returns {string}
 */

/**
 * a function to convert string or number width to rem
 * @param {string|number} width
 * @returns {string}
 */

export const convertWidthToRem = (width) => {
  if (typeof width === 'string') {
    if (width.includes('px')) {
      return `${parseInt(width.replace('px', ''))}rem`;
    }
    return width;
  } else if (typeof width === 'number') {
    return `${width}rem`;
  }
};

/**
 * a function to convert rem to pixel integer value
 * @param {string} rem
 * @returns {number}
 */

export const convertRemToPixel = (rem) => {
  if (typeof rem === 'string') {
    if (rem.includes('rem')) {
      return parseInt(rem.replace('rem', '')) * 10;
    }
    return rem;
  } else if (typeof rem === 'number') {
    return rem * 10;
  }
};

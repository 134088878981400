import styled from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';

export const StyledSecuritySection = styled.section`
  width: 100%;
  margin: auto;
  padding: 10rem 7rem;
  background: ${({ theme }) => theme.palette.common.white};

  @media ${deviceQuery.tablet} {
    padding: 7rem 1.6rem 8.7rem;
  }

  .section-head {
    width: 87rem;
    max-width: 100%;
    margin: auto;
    text-align: center;
  }

  .levels-list-container {
    width: 76rem;
    max-width: 100%;
    margin: 6rem auto 4.8rem;
    position: relative;

    .highlighter {
      position: absolute;
      width: calc(67% / 3);
      height: calc(100% + 4.8rem);
      top: 0;
      left: calc(
        33% +
          ${({ $active = 0, $hover = null }) =>
            $hover !== null
              ? `${(67 / 3) * $hover}%`
              : `${(67 / 3) * $active}%`}
      );
      background: ${({ theme }) => theme.palette.common.white};
      border: 1px solid ${({ theme }) => theme.palette.common.border.lightGray};
      box-shadow: 0px 42px 84px rgba(30, 52, 92, 0.157998);
      border-radius: 1.6rem;

      .success-tick {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        float: right;
        margin: -0.9rem -1.2rem 0 0;
      }

      @media ${deviceQuery.mobile} {
        border-radius: 1.2rem;
      }
    }

    .levels-list {
      list-style: none;
      width: 100%;
      position: relative;

      li {
        display: flex;
        border-bottom: 1px solid
          ${({ theme }) => theme.palette.common.border.lightGray};

        .item-name,
        .level {
          padding: 2.2rem 0;
        }

        .item-name {
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 2.7rem;

          @media ${deviceQuery.mobile} {
            font-size: 1.2rem;
            line-height: 148.6%;
          }
        }

        .heading {
          font-weight: 800;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          font-size: 1.5rem;
          line-height: 118.1%;

          &.item-name {
            .level-image {
              width: 3.6rem;
              height: 3.6rem;
              margin-right: 8px;
            }
          }

          &.level {
            .level-image {
              width: 2.4rem;
              height: 2.4rem;
              margin-right: 8px;
            }
          }

          @media ${deviceQuery.mobile} {
            font-size: 9px;

            &.item-name {
              .level-image {
                width: 2.2rem;
                height: 2.2rem;
                margin-right: 4px;
              }
            }

            &.level {
              .level-image {
                width: 1.4rem;
                height: 1.4rem;
                margin-right: 4px;
              }
            }
          }
        }

        .item-name {
          width: 33%;
          text-align: left;
        }

        .level {
          width: calc(67% / 3);
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const StyledImageComponentWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${(props) =>
    props?.freeSize ? 0 : `calc(100% / ${props?.aspectRatio})`};
  overflow: hidden;

  .image-wrapper {
    position: ${(props) => (props.freeSize ? 'static' : 'absolute')};
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => (props.freeSize ? 'auto' : '100%')};
    display: flex !important;
    align-items: center;
    justify-content: center;
    .image {
      width: 100%;
      max-height: 100%;
    }
  }

  .place-holder {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.palette.common.white}88;
  }

  .loader-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.palette.common.white}88;
    opacity: 0.5;
    /* color: ${(props) => props.theme.palette.primary.main};* */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

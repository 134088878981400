import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

const defaultCompareMethod = (prev, next) => isEqual(prev, next);

const useMemoCompare = (next, compare = defaultCompareMethod) => {
  const prevRef = useRef(null);
  const prev = prevRef.current;

  const isEqual = compare(prev, next);

  useEffect(() => {
    if (!isEqual) {
      prevRef.current = next;
    }
  });

  return isEqual ? prev : next;
};

export default useMemoCompare;

const ROUTES = {
  HOME: '/',
  TOP: '/#top',
  HOW_TO: '/#how-to',
  NFT_AVATARS: '/#nft-avatars',
  DISCOVER: '/#discover',
  CRYPTO_TRADING: '/#crypto-trading',
  I_GAMING: '/#i-gaming',
  SECURITY: '/#security',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
};

export default ROUTES;

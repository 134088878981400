export const sizeNumbers = {
  mobileXS: 320,
  mobileS: 380,
  mobile: 480,
  mobileSM: 575,
  mobileL: 600,
  tabletS: 650,
  tablet: 768,
  tabletL: 900,
  laptopS: 991,
  laptop: 1024,
  laptopL: 1200,
  desktopS: 1260,
  desktopM: 1360,
  desktop: 1440,
  desktopL: 1600,
  desktopXL: 1900,
};

export const size = {
  mobileXS: `${sizeNumbers.mobileXS}px`,
  mobileS: `${sizeNumbers.mobileS}px`,
  mobile: `${sizeNumbers.mobile}px`,
  mobileSM: `${sizeNumbers.mobileSM}px`,
  mobileL: `${sizeNumbers.mobileL}px`,
  tabletS: `${sizeNumbers.tabletS}px`,
  tablet: `${sizeNumbers.tablet}px`,
  tabletL: `${sizeNumbers.tabletL}px`,
  laptopS: `${sizeNumbers.laptopS}px`,
  laptop: `${sizeNumbers.laptop}px`,
  laptopL: `${sizeNumbers.laptopL}px`,
  desktopS: `${sizeNumbers.desktopS}px`,
  desktopM: `${sizeNumbers.desktopM}px`,
  desktop: `${sizeNumbers.desktop}px`,
  desktopL: `${sizeNumbers.desktopL}px`,
  desktopXL: `${sizeNumbers.desktopXL}px`,
};

export const deviceQuery = {
  mobileXS: `(max-width: ${size.mobileXS})`,
  mobileS: `(max-width: ${size.mobileS})`,
  mobile: `(max-width: ${size.mobile})`,
  mobileSM: `(max-width: ${size.mobileSM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktopM: `(max-width: ${size.desktopM})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktopL})`,
  desktopXL: `(max-width: ${size.desktopXL})`,
};

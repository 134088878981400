const WHITE = '#ffffff';
const BLACK = '#000000';

const theme = {
  palette: {
    common: {
      border: {
        light: '#E9EAF4',
        lightGray: '#E2E4E8',
      },
      background: {
        light: '#ECF2FD',
        dark: '#100F2E',
        card: '#F1F6FF',
      },
      white: WHITE,
      black: BLACK,
      hover: '#E8EEFF',
      sphere: '#2954C9',
    },

    primary: {
      main: '#2956D0',
      text: WHITE,
      hover: '#0932A3',
    },

    secondary: {},

    text: {
      primary: '#1D253C',
      secondary: '#4A5163',
    },
  },
  typography: {
    title1: '6.2rem',
    title2: '4.2rem',
    subTitle1: '2.8rem',
    subTitle2: '2.2rem',
    body1: '1.8rem',
    body2: '1.6rem',
  },
  layout: {
    header: {
      height: '12rem',
      heightMobile: '7rem',
    },
    maxWidth: '132.3rem',
  },
};

export default theme;

import styled, { css } from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const StyledUpArrowButton = styled.div`
  position: fixed;
  bottom: 7.1rem;
  right: -5%;
  opacity: 0;
  transform: rotateZ(180deg);
  transition: all 0.3s ease-in;
  visibility: hidden;
  z-index: -100;
  cursor: pointer;
  ${({ $arrowVisible }) =>
    $arrowVisible &&
    css`
      right: 2%;
      opacity: 0.4;
      transform: rotateZ(0deg);
      visibility: visible;
      z-index: 100;
    `}
  @media only screen {
    @media ${deviceQuery.tablet} {
      height: 6rem;
      width: 6rem;
    }
  }
`;

export const StyledScrollTrackerFiller = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1000;
  opacity: 0;
`;

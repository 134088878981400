import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './style';

const defaultClass = 'button';

const Button = (props) => {
  const {
    children,
    className: _className = '',
    transparent,
    round = true,
    outline,
    ...restProps
  } = props;
  const [className, setClassName] = useState(defaultClass);

  useEffect(() => {
    let classText = [defaultClass, ..._className.split(' ')].join(' ');
    setClassName(classText);
  }, [_className]);

  return (
    <StyledButton
      className={className}
      {...restProps}
      $transparent={transparent}
      $round={round}
      $outline={outline}
      type="button"
    >
      {children}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  ...StyledButton.propTypes,
  className: PropTypes.string,
  children: PropTypes.node,
  outline: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';

const UpArrowFilledIcon = ({ fillColor = 'currentColor', ...restProps }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M-1.31134e-06 30C-9.63548e-07 37.9566 3.16069 45.587 8.78694 51.2131C14.4128 56.8393 22.0436 60 30 60C37.9564 60 45.587 56.8393 51.2131 51.2131C56.8393 45.5872 60 37.9564 60 30C60 24.7342 58.6138 19.5607 55.9809 15.0001C53.3475 10.4395 49.5607 6.65267 44.9999 4.01912C40.4393 1.38621 35.2658 -1.54152e-06 30 -1.31134e-06C22.0434 -9.63548e-07 14.413 3.16069 8.78693 8.78694C3.16069 14.4128 -1.65913e-06 22.0436 -1.31134e-06 30H-1.31134e-06ZM30.7916 15.3237L42.275 26.7718C42.4944 27.0079 42.622 27.3142 42.6354 27.6361C42.6387 27.9399 42.5149 28.2316 42.293 28.44C42.085 28.6673 41.7917 28.7975 41.4832 28.8L34.5951 28.8L34.5951 44.9999L25.4035 44.9999L25.4035 28.8L18.5155 28.8C18.21 28.8021 17.917 28.6786 17.7056 28.458C17.4847 28.2492 17.3608 27.9579 17.3633 27.6541C17.3528 27.3452 17.47 27.0455 17.6876 26.8258L29.1711 15.3241C29.3904 15.1069 29.6901 14.9897 29.999 15.0002C30.2961 14.9943 30.5828 15.1119 30.7908 15.3241L30.7916 15.3237Z"
        fill={fillColor}
      />
    </svg>
  );
};

UpArrowFilledIcon.propTypes = {
  fillColor: PropTypes.string,
};

export default UpArrowFilledIcon;

import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { throttle } from 'lodash';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

import UpArrowFilledIcon from '../../assets/icons/upArrowFilledIcon';
import { StyledUpArrowButton, StyledScrollTrackerFiller } from './style';

const SCROLL_DIRECTION = {
  up: 'UP',
  down: 'DOWN',
};

const UpArrowButton = ({ visible, onClick, className = '' }) => {
  const [scrollDirection, setScrollDirection] = useState(SCROLL_DIRECTION.down);
  const [isFillerVisible, setIsFillerVisible] = useState(false);

  const previousScrollTop = useRef(0);
  const fillerRef = useRef(null);

  const arrowClickHandler = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    onClick && onClick(e);
  };

  const toggleFillerVisiblityOnIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      setIsFillerVisible(entry.isIntersecting);
    });
  }, []);

  const onScrollChangeHandler = useCallback(() => {
    const currentScrollTop = window.pageYOffset;
    let _scrollDirection;
    if (currentScrollTop > previousScrollTop.current) {
      _scrollDirection = SCROLL_DIRECTION.down;
    } else {
      _scrollDirection = SCROLL_DIRECTION.up;
    }
    setScrollDirection(_scrollDirection);
    previousScrollTop.current = currentScrollTop;
  }, []);

  const throttledOnScrollChangeHandler = useMemo(
    () => throttle(onScrollChangeHandler, 300),
    [onScrollChangeHandler]
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledOnScrollChangeHandler);
    return () => {
      window.removeEventListener('scroll', throttledOnScrollChangeHandler);
    };
  }, [throttledOnScrollChangeHandler]);

  useIntersectionObserver({
    targetRef: fillerRef,
    callback: toggleFillerVisiblityOnIntersection,
    options: { threshold: 0, rootMargin: '200px 0px 0px 0px' },
  });

  return (
    <StyledUpArrowButton
      $arrowVisible={
        (scrollDirection === SCROLL_DIRECTION.up && !isFillerVisible) || visible
      }
    >
      <Portal target={document.querySelector('body')}>
        <StyledScrollTrackerFiller ref={fillerRef} />
      </Portal>
      <UpArrowFilledIcon
        className={`up-arrow-icon ${className}`}
        onClick={arrowClickHandler}
      />
    </StyledUpArrowButton>
  );
};

UpArrowButton.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

function Portal({ children, target }) {
  if (target) {
    return ReactDOM.createPortal(children, target);
  } else {
    return null;
  }
}

export default UpArrowButton;

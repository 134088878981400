import styled from 'styled-components';
import { deviceQuery } from '../../../../styles/mediaSizes';

export const StyledGetGamaWalletSection = styled.section`
  width: 100%;
  background-color: ${(props) => props.theme.palette.common.white};
  .content {
    width: 100%;
    max-width: ${(props) => props.theme.layout.maxWidth};
    margin: auto;
    padding: 6rem 1rem 11.2rem;
    text-align: center;
    @media only screen {
      @media ${deviceQuery.tablet} {
        padding: 2rem 1rem 6.2rem;
      }
    }
    .section-title {
      font-size: ${(props) => props.theme.typography.title2};
      font-weight: 800;
      line-height: 5.2rem;
      @media only screen {
        @media ${deviceQuery.tablet} {
          font-size: 3.6rem;
          line-height: 4.4rem;
        }
      }
    }
    .section-body {
      font-size: ${(props) => props.theme.typography.body1};
      font-weight: 400;
      line-height: 3rem;
      margin: 2.4rem auto 3.2rem;
      max-width: 52.4rem;
      @media only screen {
        @media ${deviceQuery.tablet} {
          font-size: ${(props) => props.theme.typography.body2};
          line-height: 2.4rem;
        }
      }
    }
    .store-button-container {
      justify-content: center;
    }
  }
`;

import React from 'react';
import ImageComponent from '../../../../components/ImageComponent/ImageComponent';
import { StyledCryptoTradingSection } from './style';
import CryptoTradingImage from '../../../../assets/images/cryptoTradingSection/cryptoTrading.png';
import CryptoTradingMobileImage from '../../../../assets/images/cryptoTradingSection/cryptoTradingMobile.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { deviceQuery } from '../../../../styles/mediaSizes';
import { WALLET_PAGE_SECTIONS } from '../../../../constants/appConstants';

const CryptoTradingSection = () => {
  const isTablet = useMediaQuery(deviceQuery.tablet);

  return (
    <StyledCryptoTradingSection id={WALLET_PAGE_SECTIONS.CRYPTO_TRADING}>
      <div className="subsection-container">
        <div className="subsection image-subsection left-subsection">
          <ImageComponent
            src={isTablet ? CryptoTradingMobileImage : CryptoTradingImage}
            freeSize
          />
        </div>
        <div className="subsection text-subsection right-subsection">
          <div className="data-wrapper">
            <h6 className="section-title">Secure Crypto Trading</h6>
            <p className="section-desc">
              We offer the world’s leading cryptocurrencies for you to buy,
              store and swap 24/7
            </p>
            <p className="section-desc">
              Our clear UX offers you a seamless and safe trading experience
              with popular cryptocurrencies. Just pick from the list and start
              building your crypto portfolio in no time
            </p>
          </div>
        </div>
      </div>
    </StyledCryptoTradingSection>
  );
};

export default CryptoTradingSection;
